import * as Sentry from '@sentry/browser';

const ignoredRefererSet = [
  'facebook.com',
  'instagram.com',
  'google.com',
  'gogole.fr',
  't.co',
  'bing.com',
];

export default () => {
  Sentry.init({
    dsn: 'https://0e21157124fd43e3b14e0b75ff13dc49@sentry.pokernews.tech/7', // prod
    // integrations: [
    //   Sentry.browserTracingIntegration(),
    //   Sentry.captureConsoleIntegration(),
    // ],
    // tracesSampleRate: 0.2,

    allowUrls: [/pokernews\.com/],
    denyUrls: ['https://cdn.cookielaw.org'],
    ignoreErrors: [
      'top.GLOBALS',
      "Can't find variable: Map",
      'flowplayer',
      "Can't find variable: google",
      'google is not defined',
      'GVLError',
      'Blocked a frame with origin',
      "Unexpected token 'else'",
      'requestAnimationFrame',
      'The play method is not allowed by the user agent',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      "Unexpected token 'const'",
      'tcfv2',
      'webkitExitFullScreen',
      '_AutofillCallbackHandler',
      'IntersectionObserver',
      'instantSearchSDKJSBridgeClearHighlight',
      'ResizeObserver loop limit exceeded',
      'Fetch is aborted',
      'The user aborted a request.',
      'The operation was aborted',
      "undefined is not an object (evaluating 'a.P')",
      'https://cdn.cookielaw.org',
      'WKWebView API client did not respond to this postMessage',
      'Failed to fetch',
      'NetworkError',
      'cancelled',
      'Load failed',
    ],
    beforeSend(event) {
      const referer = event.request?.headers?.Referer || event.request?.headers?.referrer || '';

      if (ignoredRefererSet.some((ignoredReferer) => referer.includes(ignoredReferer))) {
        return null;
      }

      return event;
    },
  });
};
